<template>
  <div class="container">
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Account</label>
      <div class="col-3">
        <input type="text" v-model="form.account" class="form-control"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Date</label>
      <div class="col-3">
        <datepicker v-model="form.date" typeable class="form-control" style="width: 280px"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Item Name</label>
      <div class="col-6">
        <textarea
            v-model="form.itemName"
            class="form-control"
            rows="3"
        ></textarea>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Item Model</label>
      <div class="col-6">
        <textarea
            v-model="form.itemModel"
            class="form-control"
            rows="3"
        ></textarea>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Tracking Number</label>
      <div class="col-6">
        <textarea
            v-model="form.trackingNumber"
            class="form-control"
            rows="3"
        ></textarea>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Status</label>
      <div class="col-2">
        <select v-model="form.status" class="form-select">
            <option value="SHIPPED">SHIPPED</option>
            <option value="DELIVERED">DELIVERED</option>
        </select>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Order ID</label>
      <div class="col-3">
        <input type="text" v-model="form.orderId" class="form-control" />
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Note</label>
      <div class="col-6">
        <textarea
            v-model="form.note"
            class="form-control"
            rows="3"
        ></textarea>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-1 col-form-label">
        <button type="button" class="btn btn-primary" @click="formSubmit">Update Order</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "OrderEdit",
  data: function () {
    return {
      form: {
        id: this.$route.query.id
      },
      breadcrumbs: [{ name: "Order", link: "/" }, { name: "Update Order" }],
      submitted: false,
      alertContent: "",
      alertClass: "alert-danger"
    };
  },
  mounted() {
    axios
      .get("/order/" + this.form.id)
      .then((response) => {
        this.submitted = false;
        this.form = response.data;
        this.form.date = new Date(this.form.date);
      })
      .catch((error) => {
        this.submitted = false;
        this.alertContent = error.response ? error.response.data : error;
        this.alertClass = "alert-danger";
      });
  },
  methods: {
    formSubmit() {
      if (this.submitted) {
        return;
      }
      this.submitted = true;
      axios.post("/order", this.form)
        .then(() => {
          this.submitted = false;
          this.alertContent = "Order has been saved successfully!";
          this.alertClass = "alert-success";
        })
        .catch((error) => {
          this.alertContent = error.response ? error.response.data : error;
        this.alertClass = "alert-danger";
          this.submitted = false;
        });
    },
  },
};
</script>
